import OauthService from './OauthService';
import { Logger } from 'aws-amplify';
import BooleanCellRenderer from '../components/AGGrid/BooleanCellRenderer';

const logger = new Logger('DataFiltetService');

const checkboxes = [
    "enabled"
];


export default class DataFiltetService extends OauthService {

    constructor() {
        //let apiName = 'OriginsAPI';
        //let collectionPath = '/services/datafilter';
        //super(apiName, collectionPath);
        
        let apiName = 'RulesEngine';
        let collectionPath = '/data_filter';
        super(apiName, collectionPath);
    }


    formatRowData(data) {
        logger.debug("formatRowData", data);

        data.forEach((element) => {
            if (!element['last_updated_date']) element['last_updated_date'] = element['created_date'];
            if (element['created_date']) element['created_date'] = element['created_date'].substring(0, 19).replace('T', ' ');
            if (element['last_updated_date']) element['last_updated_date'] = element['last_updated_date'].substring(0, 19).replace('T', ' ');

            checkboxes.forEach((checkbox)=> {
                if (element[checkbox] === undefined) element[checkbox] = false;
            });
        });
        return data;
    }

    getMetaData(data) {
        return {
            pagination: false,
            total_records: data.length
        };
    }

    getCloneRecord(record, ) {
        record['filter_name'] = record['filter_name'] + ' (clone)';
        record['original_title'] = "";
        return record;
    }

    async save(data, metaData) {
        logger.debug("save1", data);

        //check the PK and set bool to true/false
        if (!data['GSI PK']) data['GSI PK'] = 'DATAFILTER';
        data['PK_NEW'] = data['GSI PK'] + '|' + data['filter_name'];

        checkboxes.forEach((checkbox)=> {
            if (data[checkbox])
                data[checkbox] = true;
            else
                data[checkbox] = false;
        });
        data['last_updated_by'] = metaData.app_state.user.session.login_name;

        logger.debug("save2", data, metaData);
        var response = null;
        if (data['PK'] && data['PK'] == data['PK_NEW']) { //update
            logger.debug("save update", data);
            data['revision'] = 0; // data['SK']; // which is always "v0"
            try {
                var results = await this.update(data['alarm_code'], data);

            //.then((results) => {
                logger.debug("save: update record", results);
                 response = {
                    success: true,
                    updated: true // todo: need to check result to see if updated
                 };
            //})
            }
            catch(err) {
            //.catch((err) => {
                logger.debug('save: update record failed', err, data);
                 response = {
                    success: false,
                    updated: false,
                    response: {
                        data: {
                            error: "An error occurred"
                        }
                    }
                };

            //});
            }
        }
        else { //insert
            logger.debug("save insert", data);
            try {
                var records = await this.fetch(data['PK_NEW']);
                logger.debug("save service fetch for duplicate", records);
                if (records) {
                    response = {
                        success: false,
                        updated: false,
                        response: {
                            data: {
                                error: "Duplicate filter name",
                                columns: [
                                    {
                                        key: 'alarm_code',
                                        message: "Filter name is already defined"
                                    }
                                ]

                            }
                        }
                    };
                }
                else {
                    await this.add(data)
                    .then((results) => {
                        logger.debug("save: insert record", results);
                        if (results.success == false) {
                            response =  {
                                success: false,
                                updated: false,
                                response: {
                                    data: {
                                        error: results.error.message
                                    }
                                }
                            };
                        }
                        else {
                            response = {
                                success: true,
                                updated: true
                            };
                        }
                    })
                    .catch((err) => {
                        logger.debug("save: insert record error1", err);
                        response = {
                            success: false,
                            updated: false,
                            response: {
                                data: {
                                    error: err.response.data.error.message
                                }
                            }
                        };
                    });

                }

            }
            catch (err) {
                logger.debug("save service fetch records failed", err, metaData.recordID, records);
                var error;
                if (err.response) error =err.response.data.error.message;
                else error = err.message;
                response = {
                    success: false,
                    updated: false,
                    response: {
                        data: {
                            error: error
                        }
                    }
                };
            }
        }
        return response;
    }


    getColumnDefs() {
        var column_width = 100;

        return [
        {
            headerName: 'History',
            openByDefault: false, // column: header expanded
            defaultExpanded: false,
            children: [
                {
                    field: "created_date",
                    headerName: "Creation Date",
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "readonly",
                    columnGroupShow: "open"
                },
                {
                    field: "created_by",
                    headerName: "Created By",
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "readonly",
                    columnGroupShow: "open"
                },
                {
                    field: "last_updated_date",
                    headerName: "Last Updated Date",
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "readonly",
                    newline: true,
                    sort: "desc"
                },
                {
                    field: "last_updated_by",
                    headerName: "Last Updated By",
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "text",
                    formType: "readonly",
                    columnGroupShow: "open"
                },
            ]
        },
        {
            headerName: 'Details',
            defaultExpanded: true,
            children: [
                {
                    field: "enabled",
                    headerName: "Enabled",
                    filter: 'agTextColumnFilter',
                    cellRenderer: BooleanCellRenderer,
                    width: column_width,
                    formType: "boolean",
                    defaultValue: 1,
                },
                {
                    field: "filter_name",
                    headerName: "Filter Name",
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "text",
                    required: true,
                    newline: true
                },
                {
                    field: "description",
                    headerName: "Description",
                    filter: 'agTextColumnFilter',
                    width: column_width * 4,
                    resizable: true,
                    formType: "multiline",
                    required: false
                },
                {
                    field: "filter_query",
                    headerName: "Filter Query",
                    filter: 'agTextColumnFilter',
                    width: column_width * 4,
                    resizable: true,
                    formType: "querybuilder",
                    required: false
                },
                {
                    /** Note that this field should match the querybuilder name 
                     *  with "_jsonlogic" appended to it.
                     * The value for JSONLogic will be set when the filterQuery
                     * value is saved. 
                     */
                    field: "filter_query_jsonlogic",
                    headerName: "JSONLogic",
                    filter: 'agTextColumnFilter',
                    width: column_width * 4,
                    resizable: true,
                    formType: "hidden",
                    required: false
                },
            ]
        },
        ];
    }
}
